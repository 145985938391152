import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import banner from "../../assets/images/banner-contact.png";
import line from "../../assets/images/Line 11.png";
import india from "../../assets/images/india.png";
import europe from "../../assets/images/europe.png";
import usa from "../../assets/images/usa.png";
import uk from "../../assets/images/uk (1).png";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "../../components/Loader/Loader";

const formDetails = {
  yourName: "",
  email: "",
  subject: "",
  mobileNumber: "",
  company: "",
  product: "",
  comment: "",
  check: false
};

const ContactUs = () => {
  const [errors, setErrors] = useState({
    yourName: "",
    email: "",
    mobileNumber: "",
  });
  const [formData, setFormData] = useState(formDetails);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "yourName":
        setErrors({
          ...errors,
          yourName: value.trim() === "" ? "Your name is required" : "",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: !/^\S+@\S+\.\S+$/.test(value) ? "Invalid email format" : "",
        });
        break;
      case "mobileNumber":
        setErrors({
          ...errors,
          mobileNumber: isNaN(value) ? "Enter mobile number" : "",
        });
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: name === "check" ? e.target.checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let requiredFields = [];
    if (formData.yourName.trim() === "") {
      requiredFields.push("Your Name");
    }
    if (formData.email.trim() === "") {
      requiredFields.push("Email");
    }
    if (formData.mobileNumber.trim() === "") {
      requiredFields.push("Mobile Number");
    }
    if (formData.check === false) {
      requiredFields.push("agree the privacy");
    }
    if (requiredFields.length > 0) {
      alert(
        `Please enter the following required fields: ${requiredFields.join(
          ", "
        )}`
      );
      return;
    }
    submitForm();
  };

  const submitForm = async () => {
    setIsLoader(true);
    const form = {
      name: formData.yourName,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      companyName: formData.company,
      productName: formData.product,
      subject: formData.subject,
      comment: formData.comment,
    };
    try {
      const response = await axios.post(
        "https://dotnetapi.astakenis.com/api/ContactSupport/ContactSupport",
        form
      );

      if (response.status === 200) {
        setIsLoader(false);
        setShowPopup(true);
        setFormData(formDetails);
      } else {
        setIsLoader(false);
        alert("Failed to submit contact form");
      }
    } catch (error) {
      setIsLoader(false);
      console.error("Error submitting contact form:", error);
      alert("Error submitting contact form");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact US - Astakenis</title>
        <meta
          name="description"
          content=" Astakenis offers Pharmaceutical Quality Management (QMS) Software Company in Bangalore, India, USA, UK and Netherlands"
        />
      </Helmet>
      <div className="contact">
        <div
          className="contact_banner"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="contact_banner_heading">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="contact_container">
          <div className="contact_container_location">
            <div className="contact_container_location_details">
              <div className="contact_container_location_details_section">
                {/* <div className="contact_container_location_details_section_heading">
                  <h2>Contact Us</h2>
                  <div className="contact_container_location_details_section_heading_line">
                    <img src={line} alt="img" />
                  </div>
                </div> */}
                <div className="contact_container_location_details_content">
                  <div className="contact_container_location_details_content_left">
                    <div className="contact_container_location_details_content_left_container">
                      <div className="contact_container_location_details_content_left_container_image">
                        <img src={india} alt="img" />
                      </div>
                      <div className="contact_container_location_details_content_left_container_data">
                        <h3>India</h3>
                        <p>
                          Astakenis Consulting Private Limited,
                          <br />
                          C-304, KSSIDC Complex 1st Block - 2nd Floor, <br />
                          Electronic City Phase 1, Bengaluru, Karnataka 560100.{" "}
                          <br />
                          Phone: +91 6360674997
                          <br />
                          Whatsapp: +91 6360674997 <br /> Email :{" "}
                          <a href="mailto:info@astakenis.com">
                            info@astakenis.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="contact_container_location_details_content_left_container">
                      <div className="contact_container_location_details_content_left_container_image">
                        <img src={europe} alt="img" />
                      </div>
                      <div className="contact_container_location_details_content_left_container_data">
                        <h3>Europe</h3>
                        <p>
                          Astakenis Consulting Private Limited, <br />
                          Regus Tetra Hoofddorp, <br />
                          Siriusdreef 17-27,
                          <br /> 2132 WT Hoofddorp, <br />
                          The Netherlands.
                          <br /> Email :{" "}
                          <a href="mailto:info@astakenis.com">
                            info@astakenis.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="contact_container_location_details_content_right">
                    <div className="contact_container_location_details_content_right_container">
                      <div className="contact_container_location_details_content_right_container_image">
                        <img src={usa} alt="img" />
                      </div>
                      <div className="contact_container_location_details_content_right_container_data">
                        <h3>USA</h3>
                        <p>
                          Astakenis Consulting Private Limited,
                          <br /> 183 Broadway, Suite 210,
                          <br /> Hicksville, NewYork
                          <br />
                          11801, USA.
                          <br /> Email :{" "}
                          <a href="mailto:info@astakenis.com">
                            info@astakenis.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="contact_container_location_details_content_right_container">
                      <div className="contact_container_location_details_content_right_container_image">
                        <img src={uk} alt="img" />
                      </div>
                      <div className="contact_container_location_details_content_right_container_data">
                        <h3>United Kingdom</h3>
                        <p>
                          Astakenis Consulting Private Limited,
                          <br /> 27A, Green Lane,
                          <br /> Northwood, Middlesex, HA6 2PX,
                          <br /> United Kingdom.
                          <br />
                          Email :{" "}
                          <a href="mailto:info@astakenis.com">
                            info@astakenis.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_box">
          <div className="contact_box_heading">
            <h2>Contact Us</h2>
            <div className="contact_box_heading_image">
              <img src={line} alt="img" />
            </div>
          </div>
          <div className="contact_box_details">
            <div className="contact_box_details_container">
              <div className="contact_box_details_container_content">
                <div className="contact_box_details_container_content_topinputs">
                  <input
                    type="text"
                    placeholder="Your Name"
                    name="yourName"
                    value={formData.yourName}
                    onChange={handleChange}
                    required
                  />
                  <span>{errors.yourName}</span>
                </div>
                <div className="contact_box_details_container_content_topinputs">
                  <input
                    type="email"
                    placeholder="Your -mail@gmail.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <span>{errors.email}</span>
                </div>
                <div className="contact_box_details_container_content_topinputs">
                  <input
                    type="number"
                    placeholder="Mobile No"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    required
                    pattern="[0-9]*"
                  />
                  <span>{errors.mobileNumber}</span>
                </div>
              </div>
              <div className="contact_box_details_container_content2">
                <input
                  type="text"
                  placeholder="Company Name"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  required
                />
                <select
                  name="product"
                  id=""
                  value={formData.product}
                  onChange={handleChange}
                >
                  <option hidden>Select Product</option>
                  <option value="OneQMS">OneQMS</option>
                  <option value="Quality Management">Quality Management</option>
                  <option value="Document Management">
                    Document Management
                  </option>
                  <option value="Training Management">
                    Training Management
                  </option>
                </select>
              </div>
              <div className="contact_box_details_container_content3">
                <input
                  type="text"
                  placeholder="Your Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="contact_box_details_container_comment">
                <textarea
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  placeholder="Comment"
                >
                  Comment
                </textarea>
              </div>
              <div className="contact_box_details_container_check">
                <input
                  type="checkbox"
                  name="check"
                  placeholder="Your Subject"
                  value={formData.check}
                  onChange={handleChange}
                  required
                />
                <div className="contact_box_details_container_check_content">
                  <p>
                    Note: We value your privacy and are committed to protecting
                    your personal information. In order to provide you with the
                    best possible experience on our website, we may collect
                    personally identifiable information (PII) from you. We
                    assure you that the collection, use, and storage of your PII
                    will be carried out in compliance with applicable data
                    protection laws and regulations.
                  </p>{" "}
                  <p>
                    By continuing to use our website and providing us with your
                    PII, you are giving your explicit consent for us to collect,
                    process, and store your information as described in our
                    privacy policy. Our privacy policy outlines the types of
                    information we collect, how we use it, and the security
                    measures we have in place to safeguard your data.
                  </p>
                </div>
              </div>
              <div className="contact_box_details_container_button">
                <button onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <div className="popup" onClick={handleClosePopup}>
            <div
              className="popup_container"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="popup_header">
                <h2>Thank You...</h2>
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  onClick={handleClosePopup}
                ></i>
              </div>
              <p>We have received your query. We will get back to you soon.</p>
            </div>
          </div>
        )}
        {isLoader && <Loader />}
      </div>
    </div>
  );
};

export default ContactUs;
