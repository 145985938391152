import React, { useCallback, useEffect, useState } from "react";
import "./Events.scss";
import banner from "../../assets/images/EventsBanner.png";
import { Helmet } from "react-helmet";
import headerLine from "../../assets/images/Line 11.png";
import { Pagination } from "antd";
import Loader from "../../components/Loader/Loader";
import axios from "axios";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../assets/icons";
import { debounce } from "lodash";

const Events = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [pageSize] = useState(5);
  const navigate = useNavigate();

  const fetchAllEventDetails = async (pageIndex = 0, searchStr = "") => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const payload = {
        pageIndex,
        pageSize,
        searchString: searchStr,
      };

      const queryParams = {
        isEnable: true,
      };

      const queryString = qs.stringify(queryParams, { addQueryPrefix: true });

      const response = await axios.post(
        `https://dotnetapi.astakenis.com/api/Event/GetAllEvent${queryString}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { statusCode, error, data, totalRecords } = response.data;

      if (statusCode === 200) {
        setEventDetails(data);
        setTotalEvents(totalRecords);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
      console.error(error.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllEventDetails(currentPage - 1, searchString);
  }, [currentPage, searchString]);

  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.toLocaleDateString()} @ ${date.toLocaleTimeString()}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLinkPage = (eventData) => {
    navigate(`/event-details`, { state: { eventData: eventData } });
  };

  const debouncedFetchEventDetails = useCallback(
    debounce((searchStr) => {
      setSearchString(searchStr);
      setCurrentPage(1);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedFetchEventDetails(e.target.value);
  };

  return (
    <div>
      <Helmet>
        <title>Event Details - Astakenis</title>
        <meta
          name="description"
          content=" Astakenis offers Pharmaceutical Quality Management (QMS) Software Company in Bangalore, India, USA, UK and Netherlands"
        />
      </Helmet>
      {isLoading && <Loader />}
      <div className="event">
        <div
          className="event_banner"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right top",
          }}
        >
          <div className="event_banner_heading">
            <h1>Events</h1>
          </div>
        </div>
        <div className="event_cont">
          <div className="event_cont_sec">
            <div className="event_cont_sec_heading">
              <h2>Event Details</h2>
              <img src={headerLine} alt="img" />
            </div>
            <div className="event_cont_sec_search">
              <SearchIcon className="search" />
              <input
                type="text"
                placeholder="Search Events"
                onChange={handleSearchChange}
              />
            </div>
            {eventDetails.map((item, index) => (
              <div className="event_cont_sec_body">
                <div className="event_cont_sec_body_left">
                  <div className="event_cont_sec_body_left_date">
                    <div className="event_cont_sec_body_left_date_month">
                      <span>
                        {new Date(item.fromDate).toLocaleString("en-us", {
                          month: "short",
                        })}
                      </span>
                    </div>
                    <div className="event_cont_sec_body_left_date_day">
                      <span>{new Date(item.fromDate).getDate()}</span>
                    </div>
                    <div className="event_cont_sec_body_left_date_year">
                      <span>{new Date(item.fromDate).getFullYear()}</span>
                    </div>
                  </div>
                </div>
                <div className="event_cont_sec_body_right">
                  <div className="event_cont_sec_body_right_details">
                    <div className="event_cont_sec_body_right_details_time">
                      <span>{`${formatDateTime(item.fromDate)} - ${new Date(
                        item.toDate
                      ).toLocaleTimeString()}`}</span>
                    </div>
                    <div className="event_cont_sec_body_right_details_title">
                      <h2 onClick={() => handleLinkPage(item)}>
                        {item.eventTitle}
                      </h2>
                    </div>
                    <div className="event_cont_sec_body_right_details_content">
                      <div className="event_cont_sec_body_right_details_content_text">
                        <p>{item.shortDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event_cont_sec_body_image">
                  {item.imageUrl ? (
                    <img src={item.imageUrl} alt="Event Details" />
                  ) : null}
                </div>
              </div>
            ))}
            <div style={{ paddingBottom: "20px" }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalEvents}
                onChange={handlePageChange}
                showSizeChanger={false}
                align="center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
