import React from "react";
import "./Privacy.scss";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="privacy_container">
        <div className="privacy_header">
          <h1>Privacy policy</h1>
        </div>
        <div className="privacy_content">
          <div className="privacy_content_sec">
            <h4>
              At <b>Astakenis</b>, we are committed to protecting your privacy
              and ensuring the security of your personal information. This
              Privacy Notice outlines how we collect, use, disclose, and protect
              the personal information you provide to us. By using our services
              or accessing our website, you consent to the practices described
              in this notice.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>1. Information We Collect</h3>
            <h4>
              We may collect the following types of personal information from
              you:
            </h4>
            <ul>
              <li>
                Contact information (such as name, address, email address, and
                phone number)
              </li>
              <li>Payment and billing information</li>
              <li>
                Information about your interactions with our products or
                services
              </li>
              <li>
                Information you provide when contacting our customer support
              </li>
              <li>
                Demographic information (such as age, gender, and interests) if
                voluntarily provided
              </li>
              <li>Use of Personal Information</li>
            </ul>
          </div>
          <div className="privacy_content_sec">
            <h3>2. How We Use Your Information</h3>
            <h4>
              We use the personal information we collect for the following
              purposes:
            </h4>
            <ul>
              <li>Providing and improving our products and services</li>
              <li>Processing your orders and payments</li>
              <li>
                Communicating with you about your account, inquiries, and
                updates
              </li>
              <li>Responding to your customer service requests</li>
              <li>Conducting market research and analysing user trends</li>
              <li>
                Customizing and personalizing your experience with our products
                or services
              </li>
              <li>
                Sending you promotional offers, newsletters, or other marketing
                communications (with your consent, where required by law)
              </li>
              <li>Disclosure of Personal Information, if required.</li>
            </ul>
          </div>
          <div className="privacy_content_sec">
            <h3>3. How We Share Your Information</h3>
            <h4>
              We may share your personal information with third parties in the
              following circumstances:
            </h4>
            <h4>
              <b>Service Providers</b>: We may engage trusted third-party
              service providers to assist us in delivering our products and
              services. These providers have access to your personal information
              only to the extent necessary to perform their tasks on our behalf
              and are obligated not to disclose or use it for any other purpose.
            </h4>
            <h4>
              <b>Legal Compliance</b>: We may disclose your personal information
              if required by law, governmental or regulatory authorities, or to
              protect our rights, safety, or property.
            </h4>
            <h4>
              <b>Business Transfers</b>: In the event of a merger, acquisition,
              or sale of all or a portion of our assets, your personal
              information may be transferred as part of the transaction. We will
              notify you via email or prominent notice on our website in such
              circumstances.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>4. Data Security and Retention</h3>
            <h4>
              We implement reasonable measures to protect the security of your
              personal information. However, please note that no method of
              transmission over the internet or electronic storage is completely
              secure. We cannot guarantee the absolute security of your
              information. We will retain your personal information for as long
              as necessary to fulfil the purposes outlined in this notice,
              unless a longer retention period is required or permitted by law.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>5. Legal Basis for Processing the Data</h3>
            <h4>
              While processing the data, <b>Astakenis</b> will collect the data
              as minimally as possible according to the requirements and follow
              the lawful basis for processing the personal data as per GDPR and
              other applicable regulations.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>6. Your Rights and Choices</h3>
            <h4>
              You have certain rights and choices regarding your personal
              information:
            </h4>
            <h4>
              Access and Update: You may request access to and update the
              personal information we hold about you.
            </h4>
            <h4>
              Marketing Communications: You can opt-out of receiving marketing
              communications from us by following the instructions in the
              communication or contacting us directly.
            </h4>
            <h4>
              Cookies and Tracking Technologies: You can set your browser to
              refuse all or some browser cookies or alert you when cookies are
              being sent. However, please note that disabling cookies may impact
              your experience on our website.
            </h4>
            <h4>
              Subject Access Request Procedure: Relevant individuals have the
              right to request details of their personal data that{" "}
              <b>Astakenis</b> holds on them by submitting a Subject Access
              Request.
            </h4>
            <h4>
              Every individual associated with <b>Astakenis</b> is entitled to
              the following:
            </h4>
            <h5>a. The right to access</h5>
            <h5>b. The right to rectification</h5>
            <h5>c. The right to erasure</h5>
            <h5>d. The right to restrict processing</h5>
            <h5>e. The right to object</h5>
            <h5>f. The right to data portability</h5>
            <h5>
              g. Right in relation to automated decision-making and profiling
            </h5>
          </div>
          <div className="privacy_content_sec">
            <h3>7. Children's Privacy</h3>
            <h4>
              Our products and services are not intended for individuals under
              the age of 18. We do not knowingly collect personal information
              from children. If you believe we have inadvertently collected
              personal information from a child, please contact us immediately,
              and we will take steps to delete it.
            </h4>
            <h4>
              <b>Note:</b> The minimum age limit for privacy notices in India is
              not specifically defined, but consent is required from individuals
              below 18 years of age. While in the United States, the Children's
              Online Privacy Protection Act (COPPA) governs the collection of
              personal information from children under 13 years old, and in
              Europe, the General Data Protection Regulation (GDPR) sets the age
              of consent for personal data processing at 16 years old, but
              individual EU member states may lower it to a minimum of 13 years
              old. The age limit will be applied accordingly.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>8. Changes to this Privacy Notice</h3>
            <h4>
              We may update this Privacy Notice from time to time to reflect
              changes in our practices or legal obligations. We encourage you to
              review this notice periodically for any updates. The revised
              notice will be effective immediately upon posting.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>9. Contact Us</h3>
            <h4>
              If you have any questions, concerns, or requests regarding this
              Privacy Notice or the processing of your personal information,
              please contact us at:
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>Astakenis Consulting Private Limited</h3>
            <h4>C-304, KSSIDC Complex 1st Block - 2nd Floor, </h4>
            <h4>Electronic City Phase 1,</h4>
            <h4>Bengaluru, Karnataka 560100, India.</h4>
            <h4>
              <b>Website</b>:{" "}
              <a href="www.astakenis.com" target="_blank">
                www.astakenis.com
              </a>
            </h4>
            <h4>
              <b>Email</b>: <a href='mailto:info@astakenis.com'>info@astakenis.com</a>
            </h4>
            <h4>
              <b>Phone No</b>: +91 6360674997
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h4>
              By using our services or accessing our website, you acknowledge
              that you have read and understood this Privacy Notice and agree to
              its terms.
            </h4>
          </div>
          <div className="privacy_content_sec">
            <h3>Subashchander K</h3>
            <h4>CEO</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
